import { Injectable } from '@angular/core';
import { CwsService } from '@core/services/cws.service';
import { Observable, Subject, delay, of } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';

import { Cws } from '@storykit/typings';

@Injectable({
  providedIn: 'root',
})
export class UserCacheService {
  private userCache = new Map<string, Cws.User>();

  private batchSubject = new Subject<string>();
  private batchDelay = 100;
  private pendingRequests = new Map<string, Subject<Cws.User>>();
  private isProcessing = false;
  private pageSize = 100;

  constructor(private cwsService: CwsService) {
    this.setupBatchProcessor();
  }

  private setupBatchProcessor() {
    this.batchSubject
      .pipe(
        delay(this.batchDelay),
        filter(() => !this.isProcessing),
        mergeMap((_userId) => {
          if (this.pendingRequests.size > 0) {
            this.isProcessing = true;
            const pendingIds = Array.from(this.pendingRequests.keys());
            return this.cwsService
              .getUsers({ ids: pendingIds, pageSize: this.pageSize })
              .pipe(
                tap((response) => {
                  response.users.forEach((user) => {
                    this.userCache.set(user._id, user);
                    const pending = this.pendingRequests.get(user._id);
                    if (pending) {
                      pending.next(user);
                      pending.complete();
                      this.pendingRequests.delete(user._id);
                    }
                  });
                  this.isProcessing = false;
                })
              );
          }
          return of(null);
        })
      )
      .subscribe();
  }

  getUser(userId: string): Observable<Cws.User> {
    const cachedUser = this.userCache.get(userId);
    if (cachedUser) {
      return of(cachedUser);
    }

    if (!this.pendingRequests.has(userId)) {
      const subject = new Subject<Cws.User>();
      this.pendingRequests.set(userId, subject);
      this.batchSubject.next(userId);
    }

    return this.pendingRequests.get(userId)!.asObservable();
  }

  getUserFromCache(userId: string): Cws.User | undefined {
    return this.userCache.get(userId);
  }

  getUsers(params: { search: string }): Observable<{ users: Cws.User[] }> {
    return this.cwsService
      .getUsers({
        ...params,
        pageSize: this.pageSize,
      })
      .pipe(
        tap((response) => {
          response.users.forEach((user) => {
            this.userCache.set(user._id, user);
          });
        })
      );
  }
}
