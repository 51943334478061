import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
  AUTH_HEADER,
  NGSW_BYPASS_HEADER,
  SKIP_APP_VERSION_HEADER,
  SKIP_AUTH_HEADER,
} from '@storykit/constants';
import { Cws } from '@storykit/typings';
import { VideoFont } from '@storykit/typings/src/cws';

@Injectable({ providedIn: 'root' })
export class FontUploadService {
  constructor(
    private http: HttpClient,
    private api: ApiService
  ) {}

  uploadFont(agencyId: string, s3Key: string): Observable<VideoFont> {
    return this.api
      .call<Cws.UploadFont>({
        origin: environment.api.cws.endpoint,
        path: `/agency/:agencyId/font`,
        params: { agencyId },
        method: 'POST',
        query: { agencyId },
        body: { s3Key },
      })
      .pipe(map(({ body }) => body));
  }

  uploadClientFont(clientId: string, s3Key: string): Observable<VideoFont> {
    return this.api
      .call<Cws.ClientUploadFont>({
        origin: environment.api.cws.endpoint,
        path: `/client/:clientId/font`,
        params: { clientId },
        method: 'POST',
        query: { clientId },
        body: { s3Key },
      })
      .pipe(map(({ body }) => body));
  }

  public uploadToCws(
    file: File,
    agencyId?: string,
    clientId?: string
  ): Observable<VideoFont> {
    return this.signFile(file, (agencyId ?? clientId)!).pipe(
      mergeMap((signedFile) => {
        const s3FormData = this.createS3FormData(file, signedFile);
        return this.uploadToS3SignedUrl(signedFile, s3FormData).pipe(
          map(() => signedFile)
        );
      }),
      mergeMap((signedFile) =>
        agencyId
          ? this.uploadFont(agencyId, signedFile.signedRequest.fields.key)
          : this.uploadClientFont(
              clientId!,
              signedFile.signedRequest.fields.key
            )
      )
    );
  }

  private createS3FormData(
    file: File,
    signedFile: kit.fup.ISignedUpload
  ): FormData {
    const formData = new FormData();
    const signedFileFields = signedFile.signedRequest.fields;
    for (const key in signedFileFields) {
      if (key != null) {
        const useKey = key as kit.fup.ISignedUploadFieldKeys;
        formData.set(key, signedFileFields[useKey]);
      }
    }

    formData.set('acl', 'public-read');
    formData.set('Content-Type', signedFile.type);
    formData.set('Content-Length', file.size.toString());
    formData.set('file', file);

    return formData;
  }

  private signFile(
    file: File,
    agencyId: string
  ): Observable<kit.fup.ISignedUpload> {
    const FILE_TYPE_MINIMUM_BYTES = 4100; // Number of bytes required to determine the file type
    const buffer = file.slice(0, FILE_TYPE_MINIMUM_BYTES, file.type);

    const params = new HttpParams().appendAll({
      fileName: file.name,
      fileSize: String(file.size),
      agencyId,
    });

    return this.http.post<kit.fup.ISignedUpload>(
      `${environment.api.fileUploadUrl.endpoint}/sign`,
      buffer,
      { params }
    );
  }

  private uploadToS3SignedUrl(
    signedFile: kit.fup.ISignedUpload,
    s3FormData: FormData
  ): Observable<void> {
    const options = {
      headers: {
        [SKIP_AUTH_HEADER]: 'true',
        [AUTH_HEADER]: '',
        [NGSW_BYPASS_HEADER]: 'true',
        [SKIP_APP_VERSION_HEADER]: 'true',
      },
    };

    return this.http.post<void>(
      signedFile.signedRequest.url,
      s3FormData,
      options
    );
  }
}
