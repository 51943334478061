<div [formGroup]="form()" class="premium-features-form">
  <div [formGroupName]="'videoblocks'">
    <mat-label class="form-label">Videoblocks</mat-label>
    <mat-checkbox formControlName="value" [skElementId]="e2eVideoBlocks"
      >Enable
    </mat-checkbox>
    <div class="warning-premium-features">
      Videoblocks feature is added on all
      {{ customerTypes.PRO }} & {{ customerTypes.ENTERPRISE }} service plans.
    </div>
  </div>
  <div [formGroupName]="'getty'">
    <mat-label class="form-label">Getty Images</mat-label>
    <mat-checkbox formControlName="value" [skElementId]="e2eGetty"
      >Enable
    </mat-checkbox>
  </div>
  <div [formGroupName]="'premiumMusic'">
    <mat-label class="form-label">Premium music</mat-label>
    <mat-checkbox formControlName="value" [skElementId]="e2ePremiumMusic"
      >Enable
    </mat-checkbox>
    <div class="warning-premium-features">
      Access to storyblocks premium music in music browser.
    </div>
  </div>
  <div [formGroupName]="'scriptCreators'">
    <mat-label class="form-label">Script creator</mat-label>
    <mat-checkbox formControlName="value" [skElementId]="e2eScriptCreator"
      >Enable
    </mat-checkbox>
    <div class="warning-premium-features">
      Access to Script Creator in script editor.
    </div>
  </div>
  <div [formGroupName]="'ruleSets'">
    <mat-label class="form-label">Rule sets</mat-label>
    <mat-checkbox formControlName="value" [skElementId]="e2eRuleSets"
      >Enable
    </mat-checkbox>
  </div>
</div>
