import { Injectable } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeatureService } from '@storykit/ui-components';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard {
  constructor(
    private featureService: FeatureService,
    private authService: AuthService
  ) {}

  public canActivate(): Observable<boolean> {
    return this.authService.currentUser$.pipe(
      map((user) => {
        if (user) {
          this.featureService.init(user);
        }
        // Shouldn't stop the application from working.
        return true;
      })
    );
  }
}
